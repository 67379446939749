import { createContext, useContext, useState, useMemo, useCallback, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useCore, useStorage } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useFunctions } from "@app/hooks/useFunctions"
import { useConfigContext } from "@app/providers/config"
import { useCustomerContext } from "@app/providers/customer"
import { useAnalytics } from "@app/hooks/useAnalytics"

type ContextProps = {
  id: string
  url: string
  count: number
  checkout: any
  loading: boolean
  countryCode: string
  refreshCheckout: () => void
  gotoCheckout: (event?: MouseEvent, email?: string) => void
  saveCheckout: (checkout: any) => void
  createCheckout: (countryCode: string | undefined, forceNew: boolean) => void
}

const CheckoutContext = createContext<ContextProps | undefined>(undefined)

const CheckoutProvider: React.FC = ({ children }) => {
  const {
    graphql: {
      mutations: { CHECKOUT_CREATE },
      queries: { GET_CHECKOUT },
    },
    helpers: { isBrowser },
  } = useCore()

  const { getStorage, setStorage, removeStorage } = useStorage()
  const {
    store,
    settings: { keys, functions },
  } = useConfigContext()
  const { decorateUrl } = useAnalytics()
  const { callFunction } = useFunctions()
  const { customer } = useCustomerContext()
  const { checkoutNormaliser } = useShopify()
  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkout, setCheckout] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const id = useMemo(() => checkout?.id || getStorage(keys.checkout), [getStorage, keys.checkout, checkout?.id])

  const url = useMemo(
    () => (checkout?.webUrl ? decorateUrl(checkout.webUrl.replace(store.shopifyShopDomain, store.shopifyCheckoutUrl)) : ""),
    [checkout, store, decorateUrl]
  )

  const countryCode = useMemo(
    () => checkout?.buyerIdentity?.countryCode || getStorage(keys.market) || "AU",
    [getStorage, keys.market, checkout?.buyerIdentity?.countryCode]
  )

  const count = useMemo(
    () =>
      checkout?.lineItems?.reduce(
        (count: number, lineItem: any, i: number) => (i ? count + parseInt(lineItem.quantity) : parseInt(lineItem.quantity)),
        0
      ) || 0,
    [checkout]
  )

  useEffect(() => {
    createCheckout(store.locationRegion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCheckout = useCallback(async () => {
    try {
      if (id) {
        const countryCode = getStorage(keys.market) || store.locationRegion
        const {
          data: { node: checkout },
        } = await getCheckoutQuery({ countryCode, checkoutId: id })
        return checkout
      }
      return false
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [id, keys.market, store.locationRegion, getCheckoutQuery, getStorage])

  const saveCheckout = useCallback(
    checkout => {
      try {
        setCheckout(checkoutNormaliser(checkout))
        setStorage(keys.checkout, checkout?.id)
        setStorage(keys.market, checkout?.buyerIdentity?.countryCode)
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [setCheckout, checkoutNormaliser, keys, setStorage]
  )

  const refreshCheckout = useCallback(async () => {
    const checkout = await getCheckout()
    setCheckout(checkoutNormaliser(checkout))
  }, [getCheckout, setCheckout, checkoutNormaliser])

  const createCheckout = useCallback(
    async (countryCode = "AU", forceNew = false) => {
      try {
        const existingCheckout = !forceNew && (await getCheckout())

        if (forceNew || !existingCheckout?.id || existingCheckout?.completedAt !== null || Object.keys(existingCheckout).length < 1) {
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate({
            variables: {
              input: {
                buyerIdentity: {
                  countryCode,
                },
              },
              countryCode,
            },
          })
          if (checkout) saveCheckout(checkout)
        } else {
          saveCheckout(existingCheckout)
        }
      } catch (e) {
        console.error((e as Error).message)
        const isThrottled = (e as Error).message?.toLowerCase()?.includes("throttled")
        if (!isThrottled) removeStorage(keys.checkout)
      }
    },
    [getCheckout, saveCheckout, checkoutCreate, removeStorage, keys]
  )

  const gotoCheckout = useCallback(
    async (e, email) => {
      e?.preventDefault()

      if (!isBrowser) return null

      setLoading(true)

      if (customer?.email) {
        try {
          const response = await callFunction(functions.checkoutMultipass, {
            customerEmail: customer?.email || email,
            checkoutId: checkout.id,
            webUrl: checkout.webUrl,
          })

          const url = response.status !== "error" && response.body.includes("https://") ? response.body : checkout.webUrl

          window.location.href = url
          // window.location.replace(url)
        } catch (e) {
          window.location.href = checkout?.webUrl
          // window.location.replace(checkout?.webUrl)
        }
      } else {
        // window.location.replace(checkout?.webUrl)
        window.location = checkout?.webUrl
      }
    },
    [callFunction, checkout, customer, isBrowser, functions]
  )

  const contextValue = useMemo<ContextProps>(
    () => ({
      id,
      url,
      count,
      loading,
      checkout,
      countryCode,
      gotoCheckout,
      saveCheckout,
      createCheckout,
      refreshCheckout,
    }),
    [id, url, count, loading, checkout, countryCode, gotoCheckout, saveCheckout, createCheckout, refreshCheckout]
  )

  return <CheckoutContext.Provider value={contextValue}>{children}</CheckoutContext.Provider>
}

const useCheckoutContext = (): ContextProps => ({ ...useContext(CheckoutContext) } as ContextProps)

export { CheckoutContext, CheckoutProvider, useCheckoutContext }
