import { useMemo, createContext, useContext } from "react"
import { useMutation } from "@apollo/client"
import { useCore } from "@app/hooks/useCore"

type ContextProps = {
  lineItemsReplace: any
  mutationLoading: boolean
}

export const CartContext = createContext<ContextProps | undefined>(undefined)

export const CartProvider: React.FC = ({ children }) => {
  const {
    graphql: {
      mutations: { CHECKOUT_LINE_ITEMS_REPLACE },
    },
  } = useCore()

  const [lineItemsReplace, { loading: mutationLoading }] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)

  const contextValue = useMemo<ContextProps>(
    () => ({
      mutationLoading,
      lineItemsReplace,
    }),
    [lineItemsReplace, mutationLoading]
  )

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export const useCartContext = (): ContextProps => ({ ...useContext(CartContext) } as ContextProps)
