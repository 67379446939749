import { memo, useEffect } from "react"
import { Box, VStack, HStack, Text } from "@chakra-ui/react"
import { useCheckoutContext } from "@app/providers/checkout"
import { useShopify } from "@app/hooks/useShopify"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { RewardsCartPoints } from "@components/Rewards"
import { useRewards } from "@app/hooks/useRewards"

type CartSummaryProps = {
  isMain?: boolean
}

type SummaryRowProps = {
  label: string
  value: string | React.ReactNode
}

const SummaryRow: React.FC<SummaryRowProps> = ({ label, value }) => (
  <HStack justifyContent="space-between" pb={2}>
    <Text>{label}</Text>
    <Text>{value}</Text>
  </HStack>
)

const CartSummary: React.FC<CartSummaryProps> = ({ isMain = false }) => {
  const { checkout } = useCheckoutContext()
  const { refreshRewardsUI } = useRewards()
  const { formatMoney } = useShopify()

  const subscriptionsDiscountValue = checkout?.lineItems
    ?.map((lineItem: any) => {
      const oneTimeOrSubscription = lineItem?.customAttributes?.find((field: any) => field.key === "_oneTimeOrSubscription")?.value
      const subscriptionDiscount = lineItem?.customAttributes?.find((attr: any) => attr.key === "_subscriptionDiscount")
      const itemPrice = parseFloat(lineItem?.variant?.priceV2?.amount)
      const quantity = lineItem?.quantity
      if (oneTimeOrSubscription === "monthly" && subscriptionDiscount) {
        return (itemPrice / 100) * subscriptionDiscount.value * quantity
      }
    })
    .filter(Number)
    .reduce((prev: number, curr: number) => prev + curr, 0)

  const amountDue = formatMoney(
    subscriptionsDiscountValue ? checkout?.paymentDue.amount - subscriptionsDiscountValue : checkout?.paymentDue?.amount
  )

  const discountApplied = checkout?.lineItems?.reduce((prev: any, curr: any) => {
    if (!curr?.variant?.compareAtPriceV2) return prev
    const singleDiscount = parseFloat(curr?.variant?.compareAtPriceV2?.amount) - parseFloat(curr?.variant?.priceV2?.amount)
    const quantityDiscount = singleDiscount * curr?.quantity
    return prev + quantityDiscount
  }, 0)

  const subTotal = formatMoney(
    discountApplied
      ? parseFloat(checkout?.lineItemsSubtotalPrice?.amount) + Math.abs(discountApplied)
      : checkout?.lineItemsSubtotalPrice?.amount
  )

  const formattedDiscountApplied = formatMoney(subscriptionsDiscountValue ? discountApplied + subscriptionsDiscountValue : discountApplied)

  const totalTax = formatMoney(checkout?.totalTax?.amount)
  const locales = useLocale(LOCALE_KEYS.CART)

  useEffect(() => {
    refreshRewardsUI()
  }, [refreshRewardsUI, amountDue])

  return (
    <Box textAlign="left" mb={[2, 2, 6]}>
      <VStack width="full" align="stretch" spacing={1}>
        {isMain && (
          <>
            <SummaryRow label="Subtotal" value={subTotal} />
            {/* TODO: add label to locales */}
            {!!discountApplied && <SummaryRow label="Savings" value={formattedDiscountApplied} />}
            {locales?.cartGstIncludedLabel && <SummaryRow label={locales?.cartGstIncludedLabel} value={totalTax} />}
            <SummaryRow label={locales?.cartPointsEarnedLabel} value={<RewardsCartPoints total={checkout?.paymentDue?.amount} />} />
          </>
        )}
        <HStack justify="space-between" fontWeight={700} fontSize="lg" borderTop={isMain ? "0.5px" : "none"} pt={isMain ? 2 : 0}>
          <Text fontWeight="inherit" fontSize="inherit">
            Total
          </Text>
          <Text fontWeight="inherit" fontSize="inherit">
            {amountDue}
          </Text>
        </HStack>
      </VStack>
    </Box>
  )
}

const MemoCartSummary = memo(CartSummary)
export { MemoCartSummary as CartSummary }
