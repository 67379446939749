import React from "react"
import { AppProvider } from "@app/providers/app"
import { ThemeProvider } from "@app/providers/theme"
import { ConfigProvider } from "@app/providers/config"
import { ShopifyProvider } from "@app/providers/shopify"
import { LocationProvider } from "@app/providers/location"
import { TrackingProvider } from "@app/providers/tracking"
import { CustomerProvider } from "@app/providers/customer"
import { CheckoutProvider } from "@app/providers/checkout"
import { WishListProvider } from "@app/providers/wishlist"
import { RewardsProvider } from "@app/providers/rewards"
import { SubscriptionProvider } from "@app/providers/subscription"
import { CartProvider } from "@app/providers/cart"

export const Providers: React.FC = ({ children }) => (
  <LocationProvider>
    <ConfigProvider>
      <ShopifyProvider>
        <AppProvider>
          <CustomerProvider>
            <CheckoutProvider>
              <SubscriptionProvider>
                <RewardsProvider>
                  <WishListProvider>
                    <TrackingProvider>
                      <CartProvider>
                        <ThemeProvider>{children}</ThemeProvider>
                      </CartProvider>
                    </TrackingProvider>
                  </WishListProvider>
                </RewardsProvider>
              </SubscriptionProvider>
            </CheckoutProvider>
          </CustomerProvider>
        </AppProvider>
      </ShopifyProvider>
    </ConfigProvider>
  </LocationProvider>
)
