import { useCallback, memo, useState, useEffect } from "react"
import { Drawer, Text, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Box } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { useNavigation } from "@app/hooks/useNavigation"
import { useAppContext } from "@app/providers/app"
import { NavigationHeaderItemsMobile, MobileItemTile } from "./NavigationHeaderItemsMobile"
import { ITEM_TYPES } from "./NavigationHeaderItemsDesktop"
import { AccountWidget } from "@components/Account/Widget/AccountWidget"
import { CustomLink } from "@app/components/Link"
import Markets from "@components/Markets"

import type { ItemProps } from "./NavigationHeaderItemsDesktop"

const NavigationHeaderMobile: React.FC = () => {
  const { headerMobile: items, headerExtraLinks } = useNavigation()
  const { state, dispatch } = useAppContext()
  const [activeSubMenu, setActiveSubMenu] = useState<string>("")
  const [firstTile, setFirstTile] = useState(false)

  const onToggleDrawerOpen = useCallback(() => {
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }, [dispatch, state.activeMenu])

  const handleActiveSubMenu = useCallback((subMenuKey: string) => {
    if (subMenuKey) setActiveSubMenu(subMenuKey)
  }, [])

  const getSubMenuItem = useCallback(() => {
    let itemsToDisplay = items
    let activeSubMenuTitle = ""
    let activeSubMenuUrl = ""

    if (activeSubMenu) {
      const activeSubMenuItem = items.find(({ _key }: any) => _key === activeSubMenu)

      if (activeSubMenuItem) {
        itemsToDisplay = activeSubMenuItem?.items
        activeSubMenuTitle = activeSubMenuItem?.title
        activeSubMenuUrl = activeSubMenuItem?.url
      }
    }

    return {
      itemsToDisplay,
      activeSubMenuTitle,
      activeSubMenuUrl,
    }
  }, [activeSubMenu, items])

  // computing properties on the fly instead of storing in state to prevent render
  // issues
  const { itemsToDisplay, activeSubMenuTitle, activeSubMenuUrl } = getSubMenuItem()

  const resetActiveSubMenu = useCallback(() => setActiveSubMenu(""), [])

  // Finds the first available tile for the top of the drawer, since this is out of the normal
  // rendering logic for the rest of the menu items (ie the UI is presented in a different order
  // then the data order)
  useEffect(() => {
    if (!firstTile) {
      const firstFoundTile = items.reduce(
        (prev, menuItem) => prev || menuItem.items.find((subItem: ItemProps) => subItem.type === ITEM_TYPES.TILE),
        false
      )

      if (firstFoundTile) setFirstTile(firstFoundTile)
    }
  }, [firstTile, items])

  return (
    <Drawer isOpen={state.activeMenu} onClose={onToggleDrawerOpen} placement="left" size="sm">
      <DrawerOverlay />
      <DrawerContent
        bg="brand.offWhite"
        sx={{
          "&": {
            maxW: ["308px"],
          },
        }}
      >
        {activeSubMenu ? (
          <ArrowBackIcon w={7} h={7} position="absolute" top={4} left={3} cursor="pointer" onClick={resetActiveSubMenu} />
        ) : (
          <DrawerCloseButton fontSize="md" right="unset" left={5} top={3} />
        )}

        <DrawerHeader py={3} bg="brand.lightSand">
          <Text fontWeight={900} fontFamily="heading" fontSize="3xl" ml={9}>
            {activeSubMenuTitle || <>&nbsp;</>}
          </Text>
        </DrawerHeader>
        <DrawerBody pt={5} pb={9}>
          <Box fontFamily="heading" fontSize="3xl" pt={6}>
            {firstTile && <MobileItemTile item={firstTile} />}
            <NavigationHeaderItemsMobile
              items={itemsToDisplay}
              handleActiveSubMenu={handleActiveSubMenu}
              activeSubMenu={activeSubMenu}
              activeSubMenuTitle={activeSubMenuTitle}
              activeSubMenuUrl={activeSubMenuUrl}
            />
          </Box>
          {!activeSubMenu && <NavigationHeaderMobileFooter />}
          {!activeSubMenu && (
            <Box pt={2}>
              {headerExtraLinks?.map((link: any) => (
                <Text key={link?.key} pb={2}>
                  <CustomLink external={link?.external} to={link?.url}>
                    {link?.title}
                  </CustomLink>
                </Text>
              ))}
            </Box>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const NavigationHeaderMobileFooter: React.FC = () => {
  return (
    <Box py={5} borderTop="1px" borderBottom="1px" borderColor="brand.avocado">
      <Markets />
      <AccountWidget isMobileMenu />
    </Box>
  )
}

export default memo(NavigationHeaderMobile)
