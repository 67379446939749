import { useState, useCallback, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useShopify } from "@app/hooks/useShopify"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useCheckoutContext } from "@app/providers/checkout"
import { useSubscriptionContext } from "@app/providers/subscription"

import type { ProductVariant } from "shopify-storefront-api-typings"
import { useCartContext } from "@app/providers/cart"

const useCart = () => {
  const { checkout, id: checkoutId, countryCode, saveCheckout } = useCheckoutContext()
  const { checkoutNormaliser, formatMoney } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const [errors, setErrors] = useState([])
  const { processRechargeCheckout } = useSubscriptionContext()
  const { lineItemsReplace, mutationLoading } = useCartContext()
  const [loading, setLoading] = useState(false)

  const prepareCustomAttributes = useCallback(
    attributes =>
      attributes?.map(({ key, value }) => ({
        key,
        value,
      })) || [],
    []
  )

  const addToCart = useCallback(
    async ({
      variantId,
      quantity = 1,
      customAttributes = [],
      isSubscriptionProduct = false,
    }: {
      variantId: string
      quantity?: number
      customAttributes?: any[]
      isSubscriptionProduct?: any
    }) => {
      setLoading(true)
      let alreadyInCart = false

      const lineItems =
        checkout?.lineItems
          ?.filter((lineItem: any) => lineItem?.variant !== null)
          .map((lineItem: any) => {
            if (
              lineItem?.variant?.id === variantId &&
              JSON.stringify(prepareCustomAttributes(lineItem?.customAttributes)) === JSON.stringify(customAttributes)
            ) {
              alreadyInCart = true

              return {
                customAttributes: [...prepareCustomAttributes(lineItem?.customAttributes), ...(customAttributes || [])],
                quantity: lineItem?.quantity + quantity,
                variantId,
              }
            }

            return {
              customAttributes: lineItem?.customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
                key,
                value,
              })),
              quantity: lineItem?.quantity,
              variantId: lineItem?.variant?.id,
            }
          }) || []

      const newLineItems = [
        ...(alreadyInCart
          ? lineItems
          : [
              ...lineItems,
              {
                quantity,
                variantId,
                customAttributes,
              },
            ]),
      ]

      try {
        const {
          data: { checkoutLineItemsReplace: data, userErrors: errors },
        } = await lineItemsReplace({
          variables: {
            countryCode,
            checkoutId,
            lineItems: newLineItems,
          },
        })

        if (errors?.length) setErrors(errors)
        if (data) saveCheckout(data?.checkout)

        trackCartUpdate("add", variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
        const { shouldGoToNormalCheckout } = processRechargeCheckout({
          lineItems: checkoutNormaliser(data?.checkout)?.lineItems,
          isSubscriptionProduct,
        })
        setLoading(false)

        return { shouldGoToNormalCheckout }
      } catch (error) {
        console.error(error)
      }
    },
    [
      setErrors,
      saveCheckout,
      setLoading,
      trackCartUpdate,
      countryCode,
      checkout,
      checkoutId,
      checkoutNormaliser,
      prepareCustomAttributes,
      processRechargeCheckout,
      lineItemsReplace,
    ]
  )

  const addToCartMultiple = useCallback(
    async (items, isSubscriptionProduct = false) => {
      setLoading(true)
      const findVariant = (id: string, customAttributes: any) =>
        items?.find((lineItem: any) => {
          const sameId = lineItem?.variantId === id
          const sameAttributes = JSON.stringify(prepareCustomAttributes(lineItem?.customAttributes)) === JSON.stringify(customAttributes)

          return sameId && sameAttributes
        })
      const foundVariants: Array<string> = []

      const lineItems =
        checkout?.lineItems
          ?.filter((lineItem: any) => lineItem?.variant !== null)
          .map((lineItem: any) => {
            const existingVariant = findVariant(lineItem?.variantId || lineItem?.variant?.id, lineItem?.customAttributes)

            if (existingVariant) {
              foundVariants.push(lineItem?.variant?.id)
              return {
                customAttributes: [...prepareCustomAttributes(lineItem?.customAttributes), ...(existingVariant?.customAttributes || [])],
                quantity: lineItem?.quantity + existingVariant?.quantity,
                variantId: existingVariant?.variantId,
              }
            }
            return {
              customAttributes: lineItem?.customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
                key,
                value,
              })),
              quantity: lineItem?.quantity,
              variantId: lineItem?.variant?.id,
            }
          }) || []

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode,
          checkoutId,
          lineItems: [...lineItems, ...items.filter(({ variantId }: any) => !foundVariants.includes(variantId))],
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      const { shouldGoToNormalCheckout } = processRechargeCheckout({
        lineItems: checkoutNormaliser(data?.checkout)?.lineItems,
        isSubscriptionProduct,
      })
      setLoading(false)

      for (const item of items) {
        trackCartUpdate("add", item.variantId, item.quantity, checkoutNormaliser(data?.checkout)?.lineItems)
      }

      return { shouldGoToNormalCheckout }
    },
    [
      setErrors,
      saveCheckout,
      setLoading,
      trackCartUpdate,
      countryCode,
      checkout,
      checkoutId,
      checkoutNormaliser,
      prepareCustomAttributes,
      processRechargeCheckout,
      lineItemsReplace,
    ]
  )

  const removeFromCart = useCallback(
    async (id, variantId) => {
      setLoading(true)
      const quantity = checkout?.lineItems.filter(lineItem => lineItem.id === id).map(({ quantity }) => quantity)[0] || 1

      trackCartUpdate("remove", variantId, quantity, checkout?.lineItems)

      const lineItems = checkout?.lineItems
        ?.filter((lineItem: any) => lineItem?.variant !== null)
        .filter((lineItem: any) => lineItem.id !== id)
        .map((lineItem: any) => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id,
        }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: { countryCode, checkoutId, lineItems },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)
    },
    [setErrors, saveCheckout, setLoading, trackCartUpdate, countryCode, checkout, checkoutId, lineItemsReplace]
  )

  const updateQuantity = useCallback(
    async (id, variantId, quantity, action = "add") => {
      setLoading(true)
      const lineItems = checkout?.lineItems
        ?.filter((lineItem: any) => lineItem?.variant !== null)
        .map((lineItem: any) => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({ key, value })),
          }),
          quantity: lineItem.id === id ? quantity : lineItem.quantity,
          variantId: lineItem.variant.id,
        }))

      const oldQuantity = checkout?.lineItems.find((lineItem: any) => lineItem.id === id)?.quantity
      const quantityDiff = Math.abs(quantity - oldQuantity)
      action = oldQuantity < quantity ? "add" : "remove"

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: { countryCode, checkoutId, lineItems },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)

      trackCartUpdate(action, variantId, quantityDiff, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, trackCartUpdate, countryCode, checkout, checkoutId, checkoutNormaliser]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId) => {
      setLoading(true)
      const lineItems = checkout?.lineItems
        ?.filter((lineItem: any) => lineItem?.variant !== null)
        .map((lineItem: any) => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id === prevVariantId ? variantId : lineItem.variant.id,
        }))
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: { countryCode, checkoutId, lineItems },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, countryCode, checkout, checkoutId]
  )

  const updateItem = useCallback(
    async (id, variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map((lineItem: any) =>
        lineItem.id === id
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...prepareCustomAttributes(lineItem?.customAttributes),
                    ...Object.entries(customAttributes).map(attr => ({
                      key: attr[1].key,
                      value: attr[1].value,
                    })),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: { countryCode, checkoutId, lineItems },
      })

      if (errors?.length) {
        setErrors(errors)
      }
      if (data) {
        saveCheckout(data?.checkout)
      }
      setLoading(false)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, countryCode, checkout, checkoutId, prepareCustomAttributes]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)

    checkout?.lineItems?.map(({ variant, quantity }: { variant: ProductVariant; quantity: number }) =>
      trackCartUpdate("remove", variant?.id, quantity, checkout?.lineItems)
    )

    const {
      data: { checkoutLineItemsReplace: data, userErrors: errors },
    } = await lineItemsReplace({
      variables: { countryCode, checkoutId, lineItems: [] },
    })

    if (errors?.length) setErrors(errors)
    if (data) saveCheckout(data?.checkout)

    setLoading(false)
  }, [lineItemsReplace, setErrors, saveCheckout, setLoading, trackCartUpdate, countryCode, checkout, checkoutId])

  const { shipping } = useStaticQuery<GatsbyTypes.StaticShippingQuery>(graphql`
    query StaticShipping {
      shipping: sanitySettingShipping {
        threshold
        includeDiscounts
        messageBefore
        messageAfter
      }
    }
  `)

  const freeShipping = useMemo(() => {
    const threshold = shipping?.threshold ?? 100
    const includeDiscounts = shipping?.includeDiscounts ?? true
    const total = includeDiscounts ? checkout?.lineItemsSubtotalPrice?.amount : checkout?.paymentDue?.amount
    const percentage = total > threshold ? 100 : (total / threshold) * 100
    const remaining = total > threshold ? 0 : threshold - total
    const isFreeShipping = percentage === 100
    const rawMessage = isFreeShipping ? shipping?.messageAfter : shipping?.messageBefore

    // replaces {threshold} susbtring with actual threshhold value
    // replaces {remaining} substring with actual remaining value
    // replaces [substring] with bolded `substring`
    const message = rawMessage
      ?.replace("{threshold}", formatMoney(threshold))
      ?.replace("{remaining}", formatMoney(remaining))
      ?.replace(/\[(.+)\]/, (i, match) => `<span style="font-weight: 700">${match}</span>`)

    return { threshold, percentage, message, isFreeShipping }
  }, [shipping, checkout?.paymentDue?.amount, checkout?.lineItemsSubtotalPrice?.amount, formatMoney])

  return {
    addToCart,
    addToCartMultiple,
    removeFromCart,
    updateQuantity,
    updateVariant,
    updateItem,
    clearCart,
    freeShipping,
    loading,
    errors,
    mutationLoading,
  }
}

export { useCart }
