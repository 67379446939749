const rootConfig = require("../config-app")

module.exports = {
  store: require(`./config.${process.env.GATSBY_ACTIVE_STORE || "goodnessme-box"}.js`),
  stores: {
    "goodnessme-box": require("./config.goodnessme-box.js"),
    "goodnessme-box-staging": require("./config.goodnessme-box-staging.js"),
  },
  theme: {
    color: "#000000",
    background: "#FFFFFF",
    display: "minimal-ui",
    icon: "static/images/icon.png",
    logo: "static/images/logo.svg",
  },
  settings: {
    attributes: {
      authorityToLeave: "__authority-to-leave",
    },
    product: {
      sizeOptionName: "Size",
      colourOptionName: "Color",
      tagBadgePrefix: "badge:",
      tagBrandPrefix: "brand:",
      tagSizePrefix: "size:",
      tagImageBadgePrefix: "savings:",
      tagCardBadgePrefix: "badges:",
    },
    customer: {
      tagPrefix: "preferences",
    },
    functions: {
      checkoutMultipass: "checkout-multipass",
      customerMultipass: "customer-multipass",
      customerSubscribe: "customer-subscribe",
      customerTags: "customer-tags",
      formSubmission: "form-submission",
      klaviyoBackInStock: "klaviyo-back-in-stock",
      rechargeGetSubscriptionProducts: "recharge-get-subscription-products",
      rechargeCreateCheckout: "recharge-create-checkout",
      rechargeGetCustomerPortal: "recharge-get-customer-portal",
      loyaltyLionCreateCustomerToken: "loyaltylion-create-customer-auth-token",
      loyaltyLionGetCustomerInfo: "loyaltylion-get-customer-info",
      stampedAskQuestion: "stamped-ask-question",
    },
    keys: {
      market: "market",
      shopify: "shopify",
      cart: "cart",
      country: "country",
      location: "location",
      checkout: "checkout",
      discounts: "discounts",
      customer: "customer",
      newsletter: "subscribe",
      announcement: "announcement",
      maintenance: "maintenance",
      password: "password",
      wishListUserId: "wishlist-user-id",
      subscriptionProducts: "subscription-products",
      loyaltyLionCustomer: "loyalty-lion-customer",
      loyaltyLionAuthToken: "loyalty-lion-auth-token",
      loyaltyLionAuthDate: "loyalty-lion-auth-date",
      loyaltyLionLastFetch: "loyalty-lion-last-fetch",
      customerPortalUrl: "recharge-customer-portal-url",
    },
    params: {
      checkout: "checkout_url",
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      variant: "variant",
      preview: "preview",
      searchQuery: "q",
      rechargeShopifyProductId: "shopify_product_id",
      reactifyCategory: "category",
      swymWishListId: "swymListId",
      sanityWishListId: "listId",
    },
    routes: rootConfig.routes,
    defaultRedirects: [{ source: "/account/register", destination: "/account/login", type: 301 }],
    clientPaths: [
      "/wishlist/*",
      "/shopping-list/*",
      "/account/reset/*",
      "/account/activate/*",
      "/account/orders/*",
      "/shopping-list/*",
      "/reviews/*",
      "/review/write/*",
      "/giftcard/*",
    ],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/account/wishlist",
      "/wishlist",
      "/shopping-list",
      "/search",
      "/cart",
    ],
  },
}
