import { useMemo, useState } from "react"
import { css } from "@emotion/react"
import { CustomLink } from "@components/Link"
import { Box, Stack } from "@chakra-ui/react"
import { useShopify } from "@app/hooks/useShopify"
import { useRoutes } from "@app/hooks/useRoutes"
import { useOutOfStock } from "@app/hooks/useOutOfStock"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { ProductCardVariantSelector } from "@components/ProductCard/ProductCardVariantSelector"
import { ProductAddToCart } from "@components/Product/ProductAddToCart"
import type { ResultCardProductProps } from "@usereactify/search"
import { useSubscriptionContext } from "@app/providers/subscription"
import { ProductCardPrice, ProductCardTitle } from "@app/components/ProductCard/ProductCardStyles"
import { ProductCardNotifyMe } from "@app/components/ProductCard/ProductCardNotifyMe"

type SearchFormItemAltProps = React.ComponentProps<NonNullable<ResultCardProductProps["render"]>>

const searchResult = css`
  .searchResultItem {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 16px;

    @media screen and (min-width: 600px) {
      gap: 0;
      height: 100%;
    }
  }

  .searchResultItem button {
    margin-bottom: 0;
  }

  @media screen and (min-width: 600px) {
    .searchResultItem {
      display: flex;
      flex-direction: column;
    }

    .searchResultItem__contents {
      flex: 1 0 auto;
    }
  }
`

const getSelectedVariant = ({ item, selectedVariantIdx }: { item: any; selectedVariantIdx: number }) => {
  const isSanityProduct = !!item?.shopify
  const isShopifyProduct = !isSanityProduct

  if (isSanityProduct && typeof item?.shopify?.raw === "string") {
    return JSON.parse(item?.shopify?.raw)?.variants?.[0]
  }

  if (isShopifyProduct) {
    const moreThanOneVariant = item?.variants?.length > 1

    if (moreThanOneVariant) {
      return item?.variants?.[selectedVariantIdx]
    } else {
      return item?.variants?.[0]
    }
  }
}

export const SearchFormItemAlt: React.FC<SearchFormItemAltProps> = ({ product, itemRef, handleClick }) => {
  const [selectedVariantIdx, setSelectedVariantIdx] = useState(0)
  const { productId } = product
  const { adminProductNormaliser } = useShopify()
  const { trackProductClick } = useAnalytics()
  const { urlResolver } = useRoutes()
  const imageSize = 300
  const item = adminProductNormaliser(product, { imageSize })
  const link = urlResolver(item)
  const { checkIfSubscriptionProduct } = useSubscriptionContext()
  const { isSubscriptionProduct } = checkIfSubscriptionProduct({ productId })
  const selectedVariant = useMemo(() => getSelectedVariant({ item, selectedVariantIdx }), [item, selectedVariantIdx])

  const { checkIfOutOfStock } = useOutOfStock()
  const isOutOfStock = checkIfOutOfStock({ variant: selectedVariant, product: item })

  const handleTrackingClick = () => {
    trackProductClick(item, item?.variants[0], 1)
    handleClick()
  }

  if (product.tags.includes("review-product")) return

  return (
    <Box
      ref={itemRef}
      mb={[10, 6]}
      className={isOutOfStock ? "searchResult searchResult--out-of-stock" : "searchResult"}
      css={searchResult}
    >
      <Box className="searchResultItem">
        <CustomLink to={link.url} title={link.title} onClick={handleTrackingClick}>
          <Box
            border="1px solid #F5ECE4"
            sx={{
              aspectRatio: "1",
            }}
          >
            {item.images?.length && <img src={item.images[0].src} width={imageSize} />}
          </Box>
        </CustomLink>
        <Stack direction="column" className="searchResultItem__contents">
          <CustomLink to={link.url} title={link.title} onClick={handleTrackingClick}>
            <Box mb={[1, 3]}>
              <ProductCardTitle item={item} />
            </Box>
            <ProductCardPrice variant={selectedVariant} />
          </CustomLink>
          <ProductCardVariantSelector
            item={item}
            selectedVariantIdx={selectedVariantIdx}
            setSelectedVariantIdx={setSelectedVariantIdx}
            isPlp={true}
            stripSizeSuffix={true}
          />
          <Box mt="auto!important" pt="16px">
            {isOutOfStock ? (
              <ProductCardNotifyMe variant={selectedVariant} />
            ) : (
              <ProductAddToCart variant={selectedVariant} isSubscriptionProduct={isSubscriptionProduct} />
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
