import { memo, useCallback } from "react"
import { IconButton, Box, Flex } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"
import { useCheckoutContext } from "@app/providers/checkout"
import { CustomIcon } from "@components/Icon"

const CartWidget: React.FC = () => {
  const { count } = useCheckoutContext()
  const { state, dispatch } = useAppContext()

  const handleActiveCart = useCallback(() => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }, [dispatch, state.activeCart])

  return (
    <Box position="relative">
      <IconButton
        variant="icon-button"
        title={`Open cart (${count})`}
        aria-label={`Open cart (${count})`}
        icon={<CustomIcon name="cart" width={25} height={25} />}
        size="lg"
        fontSize="x-large"
        onClick={handleActiveCart}
      />
      {!!count && (
        <Flex
          bg="brand.peachy"
          p={1}
          position="absolute"
          top={-2}
          right={4}
          borderRadius="round"
          fontSize="xxs"
          w={4}
          h={4}
          alignItems="center"
          justifyContent="center"
        >
          {count}
        </Flex>
      )}
    </Box>
  )
}

const MemoCartWidget = memo(CartWidget)
export { MemoCartWidget as CartWidget }
