import { memo, useCallback, useState, useEffect } from "react"
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  HStack,
  Box,
} from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"
import { useCheckoutContext } from "@app/providers/checkout"
import { useCheckout } from "@app/hooks/useCheckout"
import { CartShipping } from "./CartShipping"
import { CartSummary } from "./CartSummary"
import { CartMinimumSpendNotice } from "./CartMinimumSpendNotice"
import { CartList } from "./CartList"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useCore } from "@app/hooks/useCore"
import { useDom } from "@app/hooks/useDom"
import { useGifts } from "@app/hooks/useGifts"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const CartMini: React.FC = () => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const [headerOffset, setHeaderOffset] = useState(0)
  const [userRemovedTempControlledPouch, setUserRemovedTempControlledPouch] = useState(
    isBrowser ? Boolean(window?.sessionStorage?.getItem("temp-controlled-pouch-removed")) : false
  )
  const { id, count, checkout } = useCheckoutContext()
  const { state, dispatch, headerRef } = useAppContext()
  const { updateCurrency } = useCheckout()
  const { dom } = useDom()

  const getHeaderBottomPx = useCallback(
    () => Math.abs(headerRef?.current?.getBoundingClientRect().bottom),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headerRef, dom.height, dom.width]
  )

  const calculateOffset = useCallback(() => {
    const headerBottomPx = getHeaderBottomPx()

    if (headerBottomPx !== headerOffset) {
      setHeaderOffset(Math.ceil(headerBottomPx))
    }
  }, [headerOffset, getHeaderBottomPx])

  useScrollPosition(
    ({ currPos }) => {
      const scrollY = currPos?.y * -1
      const headerBottomPx = getHeaderBottomPx()

      if (scrollY > headerBottomPx) {
        if (headerOffset !== 0) {
          setHeaderOffset(0)
        }
        return
      } else {
        calculateOffset()
      }
    },
    [state?.activeCart, state?.activeSearch, state?.activeMenu, headerOffset, headerRef]
  )

  // // Calculate offset on initial load
  useEffect(() => {
    if (isBrowser && dom?.height && dom?.width) {
      calculateOffset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dom.height, dom.width, headerRef, isBrowser])

  const {
    settings: { routes },
  } = useConfigContext()
  const locales = useLocale(LOCALE_KEYS.CART)
  const { applyGiftsToCart } = useGifts()

  const onClose = useCallback(() => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }, [dispatch, state.activeCart])

  const cartHasItems = !!count

  const handleGifts = useCallback(
    async (excludeTempControlledPouch = false) => {
      if (id) await applyGiftsToCart(excludeTempControlledPouch)
    },
    [applyGiftsToCart, id]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setUserRemovedTempControlledPouch(Boolean(window?.sessionStorage?.getItem("temp-controlled-pouch-removed")))
  })

  // intentionally only run on count update
  useEffect(() => {
    if (count > 0) handleGifts(userRemovedTempControlledPouch)
  }, [id, count, handleGifts, userRemovedTempControlledPouch])

  useEffect(() => {
    if (checkout.discountApplications?.length) {
      const scriptDiscounts = checkout.discountApplications.filter(
        discount => discount.__typename === "ScriptDiscountApplication" && discount.targetType === "SHIPPING_LINE"
      )
      if (scriptDiscounts.length) {
        updateCurrency("AU")
      }
    }
  }, [checkout.discountApplications, updateCurrency])

  const minimumAmount = 50
  const cartTotal = parseInt(checkout?.paymentDue?.amount)
  const checkoutValueReached = cartTotal >= minimumAmount

  return (
    <Box>
      <Drawer isOpen={state.activeCart} placement="right" onClose={onClose} size="sm">
        <DrawerOverlay top="0" h="100dvh" />
        <DrawerContent
          bg="brand.offWhite"
          sx={{
            "&": {
              top: "0",
              maxW: ["323px", "388px"],
              maxH: "100dvh",
            },
          }}
        >
          {cartHasItems && <CartShipping px={[4, 4, 8]} />}

          <DrawerBody p={[4, 4, 8]}>
            <HStack justifyContent="space-between" borderBottom="1px" pb={4} mb={4}>
              <Heading as="p" size="h4" lineHeight={1}>
                {locales?.cartMiniTitle}
              </Heading>
              <DrawerCloseButton position="static" />
            </HStack>
            <CartList />
            {/* {cartHasItems && <CartDiscount />} */}
          </DrawerBody>
          {cartHasItems && (
            <DrawerFooter alignItems="stretch" flexDir="column" gap={2}>
              <CartSummary />
              {checkoutValueReached ? (
                <CustomLink to={routes.CART} variant="unstyled">
                  <Button variant="solid" w="full" onClick={onClose}>
                    {locales?.cartMiniViewMainCart}
                  </Button>
                </CustomLink>
              ) : (
                <CartMinimumSpendNotice minimumAmount={minimumAmount} ctaText={locales?.cartMiniViewMainCart} isMinicart />
              )}
              <Button variant="outline-no-underline" w="full" onClick={onClose}>
                {locales?.cartContinueShopping}
              </Button>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

const MemoCartMini = memo(CartMini)
export { MemoCartMini as CartMini }
