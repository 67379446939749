import { memo, useCallback, useMemo } from "react"
import { Button, HStack, Skeleton } from "@chakra-ui/react"
import { useCart } from "@app/hooks/useCart"
import { useAppContext } from "@app/providers/app"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useCheckoutContext } from "@app/providers/checkout"
import { useCore } from "@app/hooks/useCore"
import { QuantitySelector } from "@components/QuantitySelector"

import type { ProductVariant } from "shopify-storefront-api-typings"

type ProductAddToCartProps = {
  loading?: boolean
  isSubscriptionProduct?: boolean
  height?: number | Array<number>
  variant?: ProductVariant
  fontSize?: string | Array<string>
  marginBottom?: number | Array<number>
  subscriptionData?: {
    isSubscriptionAndOneTimeProduct?: boolean
    purchaseRecurrence?: "monthly" | "one-time"
    subscriptionDiscountPercentage?: number
  }
}

const ProductAddToCart: React.FC<ProductAddToCartProps> = ({
  loading = false,
  isSubscriptionProduct,
  height,
  fontSize = ["sm", "sm", "md"],
  variant,
  marginBottom = 0,
  subscriptionData,
}) => {
  const locales = useLocale(LOCALE_KEYS.CART)
  const { checkout } = useCheckoutContext()
  const { addToCart, loading: cartLoading, mutationLoading } = useCart()

  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { state, dispatch } = useAppContext()

  const decodedVariantId = useMemo(() => decodeBase64(variant?.id), [decodeBase64, variant?.id])

  const handleAddToCart = useCallback(async () => {
    const isEmptyCart = checkout?.lineItems?.length === 0

    const subscriptionOrOneTimeAttributes = subscriptionData?.isSubscriptionAndOneTimeProduct
      ? subscriptionData?.purchaseRecurrence === "monthly"
        ? [
            { key: "_oneTimeOrSubscription", value: "monthly" },
            { key: "_subscriptionDiscount", value: `${subscriptionData?.subscriptionDiscountPercentage}` },
          ]
        : [
            { key: "_oneTimeOrSubscription", value: "one-time" },
            { key: "_subscriptionDiscount", value: `${subscriptionData?.subscriptionDiscountPercentage}` },
          ]
      : []

    const { shouldGoToNormalCheckout } =
      (await addToCart({
        variantId: decodedVariantId,
        isSubscriptionProduct,
        customAttributes: subscriptionOrOneTimeAttributes,
      })) || {}

    if (isEmptyCart && !cartLoading && shouldGoToNormalCheckout) {
      dispatch({
        type: "cart",
        payload: !state.activeCart,
      })
    }
  }, [
    checkout?.lineItems?.length,
    subscriptionData?.isSubscriptionAndOneTimeProduct,
    subscriptionData?.purchaseRecurrence,
    subscriptionData?.subscriptionDiscountPercentage,
    addToCart,
    decodedVariantId,
    isSubscriptionProduct,
    cartLoading,
    dispatch,
    state.activeCart,
  ])

  const currVariantInCart = useMemo(
    () => checkout?.lineItems?.find((lineItem: any) => lineItem?.variant?.id === decodedVariantId),
    [checkout?.lineItems, decodedVariantId]
  )

  const getButtonText = useCallback(() => {
    const addToCartTxt = locales?.addToCart || "Add to Cart"
    const addingToCartTxt = locales?.addingToCart || "Adding to Cart"
    const addedToCartTxt = locales?.addedToCart || "Added!"

    if (currVariantInCart) return addedToCartTxt
    if (cartLoading) return addingToCartTxt

    return addToCartTxt
  }, [cartLoading, locales?.addToCart, locales?.addedToCart, locales?.addingToCart, currVariantInCart])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const buttonText = useMemo(() => getButtonText(), [getButtonText, currVariantInCart])

  if (!variant) {
    return <Skeleton h={10} />
  }

  return (
    <HStack spacing={4} align="stretch">
      {currVariantInCart ? (
        <QuantitySelector width="full" productVariant={currVariantInCart} height={height} mutationLoading={mutationLoading} />
      ) : (
        <Button
          height={height}
          variant="solid"
          w="full"
          p="unset"
          maxW="full"
          mb={marginBottom}
          onClick={handleAddToCart}
          fontSize={fontSize}
          isLoading={cartLoading || loading}
          isDisabled={cartLoading || loading || mutationLoading}
          minW="unset"
          loadingText={locales?.addingToCart || ""}
        >
          {buttonText}
        </Button>
      )}
    </HStack>
  )
}

const MemoProductaddToCart = memo(ProductAddToCart)
export { MemoProductaddToCart as ProductAddToCart }
