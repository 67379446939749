import { useCallback, useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useShopify } from "@app/hooks/useShopify"
import { useCheckoutContext } from "@app/providers/checkout"
import { useSubscriptionContext } from "@app/providers/subscription"

import { useCore } from "@app/hooks/useCore"
import { useCart } from "@app/hooks/useCart"

/*
 * @TODO
 * Clean up this file. Make it more readable
 * When applying the rules, simplify and streamline and add comments
 * Document in Notion what's going on
 */

import type { Product, ProductVariant } from "shopify-storefront-api-typings"

type SampleProduct = {
  variant: ProductVariant
}

type GiftProduct = {
  variant: ProductVariant
}

type QualifyingProductProps = {
  products?: Array<Product | GatsbyTypes.SanityProduct>
}

export const useGifts = () => {
  const { checkout } = useCheckoutContext()

  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { addToCart, addToCartMultiple, loading, removeFromCart } = useCart()
  const { processRechargeCheckout } = useSubscriptionContext()
  const { checkoutNormaliser } = useShopify()

  const [adding, setAdding] = useState<boolean | string>(false)

  const {
    promotion: { freeSamples, promotions },
  } = useStaticQuery<GatsbyTypes.StaticGiftsQuery>(graphql`
    query StaticGifts {
      promotion: sanitySettingPromotions {
        freeSamples {
          title
          enabled
          enabled
          message: title
          useProducts
          tags
          thresholdTotal
          thresholdSpend
          thresholdQuantity
          cartProduct: sampleProduct {
            shopify {
              deleted
              published
              raw
              handle
              id
            }
          }
          samples {
            key: _key
            title
            sample {
              shopify {
                deleted
                published
                raw
                handle
                id
              }
            }
          }
        }
        promotions: gifts {
          key: _key
          enabled
          message: title
          allowStacking
          useProducts
          thresholdTotal
          thresholdSpend
          thresholdQuantity
          gift: gifts {
            shopify {
              deleted
              published
              raw
              handle
              id
            }
          }
          tags
          products {
            shopify {
              deleted
              published
              raw
              handle
              id
            }
          }
        }
      }
    }
  `)

  const applyGiftRules = useCallback(
    ({ qualifyingProductIds = [], tags = [], thresholdTotal = 0, thresholdQuantity = 0, thresholdSpend = 0, useProducts = false }) => {
      const cartTotal = Number(checkout?.paymentDue?.amount) * 100
      // Rule 1: Does the cart total exceed the specified ammount?
      const formattedTotal = Number(thresholdTotal?.toString()?.split(`.`)?.[0] || 100) * 100

      if (cartTotal < formattedTotal) return false

      // Apply rules to tags or products
      const matchingLineItems = useProducts
        ? checkout?.lineItems?.filter(
            ({ variant }) =>
              qualifyingProductIds?.filter(id => Number(decodeShopifyId(variant?.product?.id, "Product")) === id).length ===
                qualifyingProductIds?.length && variant
          )
        : checkout?.lineItems?.filter(
            ({ variant }) => tags?.filter(tag => variant?.product?.tags?.includes(tag)).length === tags?.length && variant
          )
      // Return if no matching items are found
      if (!matchingLineItems) return false

      // Add up the quantity from all matching line items
      const matchingItemsQty = matchingLineItems?.reduce((x, { quantity }) => x + quantity, 0)

      // Rule 2: Does the quantity of all relevant line items exceed the specified ammount?
      const formattedQuantity = Number(thresholdQuantity?.toString()?.split(`.`)?.[0] || 100)
      if (matchingItemsQty < formattedQuantity) return false

      // Add up the total cost of all matching line items
      const matchingItemsTotal = matchingLineItems?.reduce(
        (x, { quantity, variant }) => x + Number(variant?.priceV2?.amount) * quantity * 100,
        0
      )

      // Rule 3: Does the total cost of all relevant line items exceed the specified ammount?
      const formattedItemsTotal = Number(thresholdSpend?.toString()?.split(`.`)?.[0] || 100) * 100
      if (matchingItemsTotal < formattedItemsTotal) return false
      return true
    },

    [checkout?.lineItems, checkout?.paymentDue?.amount, decodeShopifyId]
  )

  const samplesEnabled = useMemo(
    () => freeSamples?.enabled && applyGiftRules(freeSamples),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [freeSamples, checkout?.lineItems, applyGiftRules]
  )

  const samples = useMemo(
    () =>
      samplesEnabled
        ? freeSamples?.samples
            ?.filter(({ sample }) => sample?.shopify?.published && !sample?.shopify?.deleted && sample?.shopify?.raw)
            ?.map(({ key, title, sample }) => {
              let rawSample: SampleProduct | null = null
              try {
                if (sample?.shopify?.raw) rawSample = JSON.parse(sample?.shopify?.raw)
              } catch (error) {
                console.error("Error parsing raw shopify sample", error)
              }

              return {
                key,
                message: title,
                sample: {
                  ...(rawSample || {}),
                  variant: rawSample?.variants?.[0],
                  legacyId: decodeShopifyId(rawSample?.variants[0]?.id, "ProductVariant"),
                },
              }
            })
        : [],
    [samplesEnabled, freeSamples?.samples, decodeShopifyId]
  )

  const handleAddSampleToCart = useCallback(
    async key => {
      const sample = samples?.find(sample => sample?.key === key)
      const customAttributes = [
        {
          key: "_isSample",
          value: "true",
        },
        {
          key: "_giftMessage",
          value: sample?.message,
        },
      ]
      setAdding(sample?.key)

      if (!adding && !loading) {
        await addToCart({ variantId: sample?.sample?.variants[0]?.id, quantity: 1, customAttributes })
        setAdding(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adding, loading, addToCart, checkout?.lineItems, samples]
  )

  const handleRemoveFromCart = useCallback(
    async product => {
      removeFromCart(product?.id, product?.variant?.id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkout?.lineItems]
  )

  const sampleCartPlaceholder = useMemo(() => {
    const { id, raw } = freeSamples?.cartProduct?.shopify || {}
    const rawCartProduct = raw && JSON.parse(raw)
    return id && raw
      ? {
          key: id,
          message: "",
          gift: {
            ...rawCartProduct,
          },
          legacyId: decodeShopifyId(rawCartProduct?.variants[0]?.id, "ProductVariant"),
        }
      : {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allGifts = useMemo(
    () =>
      promotions
        ?.filter(({ enabled, gift }) => enabled && gift?.shopify?.published && !gift?.shopify?.deleted && gift?.shopify?.raw)
        // Used for testing as it avoids checking the products availability:
        // ?.filter(({ enabled }) => enabled)
        ?.map(item => {
          let rawGift: GiftProduct | null = null
          let qualifyingProductIds: QualifyingProductProps | null = null
          try {
            if (item?.gift?.shopify?.raw) rawGift = JSON.parse(item?.gift?.shopify?.raw)
          } catch (error) {
            console.error("Error parsing raw shopify gift", error)
          }
          try {
            if (item?.useProducts && item?.products)
              qualifyingProductIds = item?.products?.map(qualifyingProduct => Number(qualifyingProduct?.shopify?.id))
          } catch (error) {
            console.error("Error parsing raw shopify qualifying products", error)
          }

          return {
            ...item,
            gift: {
              ...(rawGift || {}),
              variant: rawGift?.variants?.[0],
            },
            qualifyingProductIds: [...(qualifyingProductIds?.map(productId => productId) || [])],
          }
        })
        ?.map(item => ({
          ...item,
          legacyId: decodeShopifyId(item?.gift?.variant?.id, "ProductVariant"),
          // Used for testing:
          // tags: ["brand:Proper Crisps", "dietary:vegan"],
          // useProducts: false,
        })),
    [promotions, decodeShopifyId]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applicableGifts = useMemo(() => allGifts?.filter(gift => applyGiftRules(gift)), [allGifts, checkout?.lineItems, applyGiftRules])

  const giftsInCart = useMemo(
    () =>
      checkout?.lineItems?.filter(({ customAttributes }) => customAttributes && customAttributes?.find(({ key }) => key === "isGift")) ||
      [],
    [checkout?.lineItems]
  )
  const sampleInCart = useMemo(
    () =>
      checkout?.lineItems?.filter(({ customAttributes }) => customAttributes && customAttributes?.find(({ key }) => key === "_isSample")) ||
      [],
    [checkout?.lineItems]
  )

  const applyGiftsToCart = useCallback(
    async (excludeTempControlledPouch = false) => {
      const samplePlaceholderRequiredInCart =
        samples
          ?.filter(
            ({ legacyId }) =>
              !checkout?.lineItems?.find(({ customAttributes }) =>
                customAttributes?.find(({ key, value }) => key === "_variant_id" && value === legacyId?.toString())
              )
          )
          ?.filter(
            () =>
              !checkout?.lineItems?.find(
                ({ customAttributes }) =>
                  customAttributes?.find(({ key, value }) => key === "_isSamplePlaceholder" && value === "true") ||
                  customAttributes?.find(({ key, value }) => key === "_isSample" && value === "true")
              )
          ) || []

      const samplePlaceholderNotRequiredInCart =
        samples
          ?.filter(
            ({ legacyId }) =>
              !checkout?.lineItems?.find(({ customAttributes }) =>
                customAttributes?.find(({ key, value }) => key === "_variant_id" && value === legacyId?.toString())
              )
          )
          ?.filter(() =>
            checkout?.lineItems?.find(({ customAttributes }) =>
              customAttributes?.find(({ key, value }) => key === "_isSample" && value === "true")
            )
          ) || []

      const giftsToAdd =
        applicableGifts?.filter(
          ({ legacyId }) =>
            !giftsInCart?.find(({ customAttributes }) =>
              customAttributes?.find(({ key, value }) => key === "_variant_id" && value === legacyId?.toString())
            )
        ) || []

      const giftsToRemove =
        giftsInCart?.filter(
          ({ customAttributes }) =>
            !applicableGifts?.find(({ legacyId }) =>
              customAttributes?.find(({ key, value }) => key === "_variant_id" && value === legacyId?.toString())
            )
        ) || []

      if (!loading) {
        const itemsToAddToCart = []
        if (samplePlaceholderRequiredInCart.length) {
          for (const item of [sampleCartPlaceholder]) {
            const customAttributes = [
              {
                key: "_isSamplePlaceholder",
                value: "true",
              },
              {
                key: "_giftMessage",
                value: item?.message || "",
              },
              {
                key: `_variant_id`,
                value: item?.legacyId?.toString(),
              },
            ]

            // if (!adding && !loading) {
            //   await addToCart({ variantId: item?.gift?.variants[0]?.id, quantity: 1, customAttributes })
            // }
            itemsToAddToCart.push({ variantId: item?.gift?.variants[0]?.id, quantity: 1, customAttributes })
          }
        }
        if (giftsToAdd.length) {
          for (const item of giftsToAdd) {
            const customAttributes = [
              {
                key: "isGift",
                value: "true",
              },
              {
                key: "_giftMessage",
                value: item?.message || "",
              },
              {
                key: `_variant_id`,
                value: item?.legacyId?.toString(),
              },
            ]

            if (excludeTempControlledPouch && item?.tags?.includes("heat-sensitive")) continue

            // await addToCart({ variantId: item?.gift?.variants[0]?.id, quantity: 1, customAttributes })
            itemsToAddToCart.push({ variantId: item?.gift?.variants[0]?.id, quantity: 1, customAttributes })
          }
        }

        if (itemsToAddToCart.length) {
          addToCartMultiple(itemsToAddToCart)
        } else {
          processRechargeCheckout({
            lineItems: checkoutNormaliser(checkout)?.lineItems,
            isMainCart: true,
          })
        }

        if (!samples.length || samplePlaceholderNotRequiredInCart.length) {
          const samplesToRemove =
            checkout?.lineItems?.filter(({ customAttributes }) =>
              samplePlaceholderNotRequiredInCart.length
                ? customAttributes?.find(({ key, value }) => key === "_isSamplePlaceholder" && value === "true")
                : customAttributes?.find(({ key, value }) => key === "_isSample" && value === "true") ||
                  customAttributes?.find(({ key, value }) => key === "_isSamplePlaceholder" && value === "true")
            ) || []

          if (!loading && samplesToRemove.length) {
            for (const item of samplesToRemove) {
              await removeFromCart(item?.id, item?.product?.id, item?.variant?.id)
            }
          }
        }

        if (giftsToRemove.length) {
          for (const item of giftsToRemove) {
            await removeFromCart(item?.id, item?.product?.id, item?.variant?.id)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applicableGifts,
      checkout?.lineItems,
      loading,
      sampleCartPlaceholder,
      samples,
      addToCartMultiple,
      removeFromCart,
      giftsInCart,
      processRechargeCheckout,
    ]
  )

  return {
    adding,
    applyGiftsToCart,
    handleAddSampleToCart,
    handleRemoveFromCart,
    cartTitle: freeSamples?.title,
    samples,
    sampleInCart,
    giftsInCart,
  }
}
