import { useCallback } from "react"
import { useAppContext } from "@app/providers/app"
import { useCheckoutContext } from "@app/providers/checkout"
import { useCustomerContext } from "@app/providers/customer"
import { useAnalytics } from "@app/hooks/useAnalytics"

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct } = useAppContext()
  const { trackProductView } = useAnalytics()
  const { checkout } = useCheckoutContext()
  const { customer } = useCustomerContext()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes("products") && product) {
        let currentProduct = product

        try {
          currentProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product
        } catch (e) {
          console.error((e as Error).message)
        }

        if (!activeProduct || activeProduct.id !== currentProduct.id) {
          setActiveProduct(currentProduct)
          if (checkout?.id && customer?.id) {
            setTimeout(() => {
              trackProductView(currentProduct, currentProduct.variants[0], false)
            }, 400)
          }
        }
      } else {
        if (activeProduct !== false) setActiveProduct(false)
      }
    },
    [activeProduct, setActiveProduct, trackProductView, checkout, customer]
  )

  return { activeProduct, selectProduct }
}
