import { memo } from "react"
import { Button } from "@chakra-ui/react"
import { useShop } from "@app/hooks/useShop"
import { useCheckout } from "@app/hooks/useCheckout"
import { useCheckoutContext } from "@app/providers/checkout"

const Markets: React.FC = () => {
  const { checkout } = useCheckoutContext()
  const { updateCurrency, loading } = useCheckout()
  const { shop } = useShop()

  return (
    <>
      {shop?.domains?.map(
        //@ts-ignore
        ({ localization: { country } }) =>
          country && (
            <Button
              key={country}
              onClick={() => updateCurrency(country)}
              isDisabled={country === checkout?.buyerIdentity?.countryCode}
              isLoading={loading && country !== checkout?.buyerIdentity?.countryCode}
              size="xs"
              ml={2}
            >
              {country}
            </Button>
          )
      )}
    </>
  )
}
export default memo(Markets)
